// extracted by mini-css-extract-plugin
export var bgDisabledColor = "#e0e0e3";
export var bgDisabledBorderColor = "#cbccd3";
export var bgEnabledColor = "rgba(60, 185, 160, 0.2)";
export var bgEnabledBorderColor = "#3cb9a0";
export var leverDisabledColor = "#fff";
export var leverEnabledColor = "var(--color-green)";
export var desktopBaseContainer = "common-module--desktopBaseContainer--x3wV3";
export var mobileBaseContainer = "common-module--mobileBaseContainer--ETt9z";
export var consultingButton = "common-module--consultingButton--oGWGK";
export var transparent = "common-module--transparent--vVlFp";
export var topButtonIcon = "common-module--topButtonIcon--Clb74";
export var speechBubble = "common-module--speechBubble--2jWl6";
export var speechBubbleContainer = "common-module--speechBubbleContainer--r1kHJ";
export var useCaseTopContentContainer = "common-module--useCaseTopContentContainer--gk+Gc";
export var useCaseTopTitle = "common-module--useCaseTopTitle--MOicm";
export var useCaseTopDesc = "common-module--useCaseTopDesc--uA4Br";
export var mobileUseCaseTopContentContainer = "common-module--mobileUseCaseTopContentContainer--T3DOB";
export var mobileUseCaseTopTitle = "common-module--mobileUseCaseTopTitle--ljfSg";
export var mobileUseCaseTopDesc = "common-module--mobileUseCaseTopDesc--wUCoO";
export var appDownloadButton = "common-module--appDownloadButton--e77CZ";
export var usecaseAppDownloadButton = "common-module--usecaseAppDownloadButton--rW1l3";
export var appDownloadIcon = "common-module--appDownloadIcon--PphB1";
export var useCaseFeatureContentContainer = "common-module--useCaseFeatureContentContainer--o7bsJ";
export var useCaseFeatureTitle = "common-module--useCaseFeatureTitle--nPsls";
export var useCaseFeatureDescContainer = "common-module--useCaseFeatureDescContainer--oGO39";
export var useCaseFeatureLeftDesc = "common-module--useCaseFeatureLeftDesc--O0br1";
export var textUnderline = "common-module--textUnderline--tagen";
export var useCaseFeatureRightDescContainer = "common-module--useCaseFeatureRightDescContainer--woI-O";
export var useCaseFeatureRightDesc = "common-module--useCaseFeatureRightDesc--yHMdr";
export var startNowButton = "common-module--startNowButton--oPQgx";
export var mobileUseCaseFeatureTopContainer = "common-module--mobileUseCaseFeatureTopContainer--Qqf+p";
export var mobileUseCaseFeatureTitle = "common-module--mobileUseCaseFeatureTitle--N0iyj";
export var mobileSpeechBubbleContainer = "common-module--mobileSpeechBubbleContainer--f608E";
export var mobileUseCaseFeatureImageContainer = "common-module--mobileUseCaseFeatureImageContainer--ZrnI3";
export var mobileUseCaseFeatureImage = "common-module--mobileUseCaseFeatureImage--r4MoE";
export var mobileUseCaseFeatureBottomContainer = "common-module--mobileUseCaseFeatureBottomContainer--OFWvP";
export var mobileUseCaseFeatureDescContainer = "common-module--mobileUseCaseFeatureDescContainer--hcEsF";
export var mobileUseCaseFeatureDesc = "common-module--mobileUseCaseFeatureDesc--RjXuh";
export var dropDownQNA = "common-module--dropDownQNA--GCRr3";
export var dropDownQNATitle = "common-module--dropDownQNATitle--l33g+";
export var open = "common-module--open--cAbAq";
export var dropDownQNABody = "common-module--dropDownQNABody--8nIT9";
export var switchComp = "common-module--switchComp--7zFze";
export var switchInput = "common-module--switchInput--XqkpZ";
export var switchLabel = "common-module--switchLabel--ZS0ww";
export var ribbon = "common-module--ribbon--OvkUj";
export var ribbonTopLeft = "common-module--ribbonTopLeft--tZGqb";
export var gradientBG = "common-module--gradientBG--ga+Ua";
export var gradient = "common-module--gradient--RU8wN";
export var photoWall = "common-module--photoWall--Vd1Hi";
export var introVideoBtn = "common-module--introVideoBtn--UOaK8";
export var introVideoLabel = "common-module--introVideoLabel--8quHi";
export var playSymbol = "common-module--playSymbol--JPn4D";