module.exports = {
  idAboutFeatureAddItem: "feature-additem",
  idAboutFeatureStatus: "feature-status",
  idAboutFeatureTx: "feature-tx",
  idFeatureBarcodelabel: "feature-barcodelabel",
  idFeatureLocation: "feature-location",
  idFeatureLowstock: "feature-lowstock",
  idFeatureSummary: "feature-summary",
  idFeatureViewPastQuantity: "feature-view-past-quantity",
  urlConsultingEn: "https://boxhero-invt.typeform.com/to/BAjooflm",
  urlConsultingKo: "https://boxhero-invt.typeform.com/to/AgYWMNU8",
  urlDownloadApp: "https://redirect.appmetrica.yandex.com/serve/530934382265923250",
  urlDownloadAppDable: "https://redirect.appmetrica.yandex.com/serve/675474761484095974",
  urlDownloadAppKakao: "https://redirect.appmetrica.yandex.com/serve/99014008425711055",
  urlDownloadAppSearchAd: "https://redirect.appmetrica.yandex.com/serve/243082618806020699",
  urlDownloadOsx: "https://boxhero-autoupdate.s3.ap-northeast-2.amazonaws.com/BoxHero-latest.dmg",
  urlDownloadWindows: "https://boxhero-autoupdate.s3.ap-northeast-2.amazonaws.com/BoxHero-latest.exe",
  urlFaqPaymentDomestic: "/help/faq/domestic-card",
  urlKakaoTalk: "http://pf.kakao.com/_rHxgpxl/chat",
  urlStart: "https://web.boxhero-app.com/team",
  urlWhatsApp: "https://api.whatsapp.com/send?phone=827086704320",
  urlInstagram: "https://www.instagram.com/boxhero.official/",
  introVideoYoutubeIdKo: "8Qr4q2qUlzs",
};
